<template>
  <div class="px-24 sm:px-0 text-xsmall">
    <div class="bg-green min-h-[230px] flex items-center justify-center mb-24 rounded-md">
      <div>
        <img
          :src="'/lottie-animations/paper-plane.gif'"
          class="w-48 h-48 mx-auto mb-16"
        >
        <span class="font-medium">
          {{ registrationResources.pendingEmailVerification }}
        </span>
      </div>
    </div>
    <WysiwygWrapper
      v-if="!isInModal"
      :style-config="{
        'wysiwyg wysiwyg--sm mb-24': true,
      }"
      :html="verifyEmailText ? verifyEmailText : registrationResources.verifyEmailInfo"
    />
    <p v-else class="mb-16">{{ registrationResources.verifyEmailInfo }}</p>
    <GlobalsLinkHelper
      v-if="mainSnusListingPage.url"
      :to="mainSnusListingPage.url"
      class="btn btn--primary w-full"
      @click="hideModal"
    >
      {{ registrationResources.continueShopping }}
    </GlobalsLinkHelper>
    <div v-else class="text-center">
      <button class="btn btn--secondary w-full" @click="hideModal">
        {{ sharedResources.close }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useGlobalContentStore } from '~/store/globalContent';
import { useUiStore } from '~/store/ui';
import { usePageContentStore } from '~/store/pageContent';
const globalContentStore = useGlobalContentStore();
const registrationResources = useGlobalContentStore().registrationResources;
const sharedResources = useGlobalContentStore().sharedResources;
const uiStore = useUiStore();
const mainSnusListingPage = globalContentStore.pageSettings?.mainSnusListingPage;
const { enableScroll } = useDisableScroll();
const pageType = ref(useGlobalContentStore().pageType);
const pageStore = usePageContentStore();

const hideModal = () => {
  uiStore.setShowLogin(false);
  uiStore.setLoginType('login');
  enableScroll();
};

defineProps<{
  isInModal?: boolean,
  verifyEmailText?: string,
}>();

watch(
  () => pageStore.pageType,
  () => {
    pageType.value = pageStore.pageType.slice(-1)[0];
  }
);

</script>
